import { useState } from 'react'
import image from '../../assets/images/exports-2.png'
import { Trans } from 'react-i18next';
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import GuulLogo from '../../assets/logos/guul-logo.jsx'
import Modal from '../Modal/Modal';

function Banner(props) {
    const { t, i18n } = useTranslation();
    let startLang = i18n.language == 'so' ? 'en' : 'so';

    const [lang, setLang] = useState(startLang);
    const [showModal, setShowModal] = useState(false);

    const handleTranslation = () => {
        if (lang === 'so') {
            setLang('en')
            i18n.changeLanguage(lang)
        }
        if (lang === 'en') {
            setLang('so')
            i18n.changeLanguage(lang)
        }

    }




    return (
        <>
            {/*     <section className="bg-gray-900 text-white bg-[url(https://images.unsplash.com/photo-1524995997946-a1c2e315a42f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80)] bg-cover bg-blend-overlay" >
                <div
                    className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center"
                >
                    <div className="mx-auto max-w-3xl text-center">
                        <h1
                            className="bg-gradient-to-r bg-gradient-to-r from-gray-100 via-slate-300 to-slate-100 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl"
                        >
                            <Trans i18nKey="banner.exellence">

                                Cultivating Excellence
                            </Trans>


                            <span className="sm:block"> <Trans i18nKey="banner.education">Through Education and Character </Trans></span>
                        </h1>

                        <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
                            <Trans i18nKey="banner.desc">
                                We provide the path to excellence through education, shaping tomorrow's leaders with knowledge, character, and ambition.
                            </Trans>

                        </p>

                        <div className="mt-8 flex flex-wrap justify-center gap-4">
                            <a
                                className="block w-full rounded border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                                href="/get-started"
                            >
                                <Trans i18nKey="banner.button1">
                                    Get Started
                                </Trans>
                            </a>
                            <a
                                className="block w-full rounded border border-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
                                href="/about"
                            >
                                <Trans i18nKey="banner.button2">
                                    Learn More
                                </Trans>
                            </a>
                        </div>
                    </div>
                </div>
            </section> */}
            <div className="bg-white">
                <header className="bg-[#FCF8F1] bg-opacity-30">
                    <div className="px-4 mx-auto sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between h-16 lg:h-20">
                            <div className="flex-shrink-0 w-20px ">
                                <a href="#" title="" className="flex">
                                    <GuulLogo key='banner' color={'url(#linearGradient5954)'} />
                                </a>
                            </div>

                            <div className="flex items-center md:gap-7 ">
                                <nav aria-label="Global">
                                    <ul className="flex items-center gap-6 sm:gap-3 text-sm">
                                        <li className='m-30px p-3  '>
                                            <span className='text-base text-black transition-all duration-200 hover:text-opacity-50 ' key={lang}>
                                                <button onClick={() => handleTranslation()} > <FontAwesomeIcon icon={faGlobe} size='lg' /> {lang.toUpperCase()}</button>
                                            </span>
                                        </li>
                                        <li className="hidden md:block">
                                            <a
                                                onClick={(e) => props.handleNavClicks(e, props.whyRef)}
                                                className="text-base text-black transition-all duration-200 hover:text-opacity-50"
                                                href="/"

                                            >

                                                <Trans i18nKey="navigation.why">

                                                    Why GA?
                                                </Trans>
                                            </a>
                                        </li>

                                        <li  className="hidden md:block">
                                            <a
                                                onClick={(e) => props.handleNavClicks(e, props.subjectsRef)}
                                                className="text-base text-black transition-all duration-200 hover:text-opacity-50"
                                                href="/"

                                            >
                                                <Trans i18nKey="navigation.subjects">
                                                    Subjects
                                                </Trans>
                                            </a>
                                        </li>

                                        <li  className="hidden md:block">
                                            <a
                                                onClick={(e) => props.handleNavClicks(e, props.stepsRef)}
                                                className="text-base text-black transition-all duration-200 hover:text-opacity-50"
                                                href="/"
                                            >
                                                <Trans i18nKey="navigation.registration">
                                                    Registration
                                                </Trans>
                                            </a>
                                        </li>

                                        <li  className="hidden md:block">
                                            <a
                                                onClick={(e) => props.handleNavClicks(e, props.faqRef)}
                                                className="text-base text-black transition-all duration-200 hover:text-opacity-50"
                                                href="/"

                                            >
                                                <Trans i18nKey="navigation.faq">
                                                    FAQ
                                                </Trans>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="flex items-center gap-4 sm:ml-">
                                    <div className="">
                                        <a
                                            className="rounded-md bg-teal-600 text-xs px-2 py-2 md:px-5 md:py-2.5  font-medium text-white shadow"
                                            href="https://wa.me/447398191640?text=Hello+Im+Interested+in+getting+to+know+more+about+Guulacademy."
                                        >
                                            <FontAwesomeIcon icon={faWhatsapp} size='2xl'   />
                                            <span className='pl-1 hidden sm:inline-block'>+44 739 819 1640</span>
                                        </a>
                                    </div>

                                    {/* <div className="block md:hidden">
                                        <button
                                            className="rounded  p-2 text-gray-600 transition hover:text-gray-600/75"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M4 6h16M4 12h16M4 18h16"
                                                />
                                            </svg>
                                        </button>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </header>
                <section className="bg-[#FCF8F1] bg-opacity-30 py-10 sm:py-16 lg:py-24">
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
                            <div>
                                <p className="text-base font-semibold tracking-wider text-blue-600 uppercase">
                                    <Trans i18nKey="banner.place">
                                        A place to cultivate educational excellence
                                    </Trans>
                                </p>
                                <h1 className="mt-4 text-4xl font-bold text-black lg:mt-8 sm:text-6xl xl:text-8xl">
                                    <Trans i18nKey="banner.header">
                                        Connect &amp; learn from the experts
                                    </Trans>
                                </h1>
                                <p className="mt-4 text-base text-black lg:mt-8 sm:text-xl">
                                    <Trans i18nKey="banner.grow">
                                        Grow your skills and knowledge with the right mentor.
                                    </Trans>
                                </p>
                                <button
                                    className="inline-flex items-center px-6 py-4 mt-8 font-semibold text-black transition-all duration-200 bg-yellow-300 rounded-full lg:mt-16 hover:bg-yellow-400 focus:bg-yellow-400"
                                    style={{ cursor: "initial" }}
                                    onClick={() => setShowModal(true)}
                                >
                                    <Trans i18nKey="banner.contact">
                                        Contact Us Now
                                    </Trans>

                                    <svg
                                        className="w-6 h-6 ml-8 -mr-2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                </button>
                                {/*  <p className="mt-5 text-gray-600">
                                    Already joined us?{" "}
                                    <a
                                        href="#"
                                        title=""
                                        className="text-black transition-all duration-200 hover:underline"
                                    >
                                        Log in
                                    </a>
                                </p> */}
                            </div>
                            <div>
                                <img
                                    className="w-full"
                                    src={image}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {showModal ? (
                    <>
                        <Modal setShowModal={setShowModal} />
                    </>
                ) : null}


            </div>
        </>
    )
}

export default Banner