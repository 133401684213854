import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="155.668"
      height="51.702"
      version="1.1"
      viewBox="0 0 234.668 51.702"
    >
      <defs>
        <linearGradient id="linearGradient3793">
          <stop offset="0" stopColor="#002c2c" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient5954"
          x1="138.554"
          x2="290.404"
          y1="-349.339"
          y2="-349.339"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient3793"
        ></linearGradient>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -152.537 499.176)">
        <g transform="matrix(.33737 0 0 .33737 88.833 231.73)">
          <g fill="#f55" transform="translate(140.835 399.23)">
            <path
              fill="#f55"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0l-48.398 23.606V5.104z"
            ></path>
          </g>
          <g fill="#fd5" transform="translate(140.835 399.23)">
            <path
              fill="#fd5"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0l-58.598 19.337V6.291z"
            ></path>
          </g>
          <g fill="#3771c8" transform="translate(140.917 307.899)">
            <path
              fill="#3771c8"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0l-65.057 12.594-.069 91.344L-.069 91.344z"
            ></path>
          </g>
        </g>
        <text
          xmlSpace="preserve"
          style={{
            lineHeight: "0",
            InkscapeFontSpecification: "'Helvetica Neue, Normal'",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
          }}
          x="137.604"
          y="-342.464"
          fill={props.color}
          fillOpacity="1"
          strokeWidth="0.75"
          fontFamily="Helvetica Neue"
          fontSize="25"
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
          letterSpacing="-1.35"
          transform="scale(1 -1)"
        >
          <tspan
            style={{
              InkscapeFontSpecification: "'Helvetica Neue, Bold'",
              fontVariantLigatures: "normal",
              fontVariantCaps: "normal",
              fontVariantNumeric: "normal",
              fontVariantEastAsian: "normal",
            }}
            x="137.604"
            y="-342.464"
            fill={props.color}
            fillOpacity="1"
            strokeWidth="0.75"
            dx="0"
            fontFamily="Helvetica Neue"
            fontSize="25"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
          >
            GuulAcademy
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default Icon;
