
import { forwardRef } from 'react'
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';


const SVGComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlnsSvgjs="http://svgjs.dev/svgjs"
        viewBox="0 0 800 400 "
        {...props}
    >
        <path
            d="M51.121063232421875,292.3766784667969C62.18234888712565,258.44543711344403,105.82958475748698,95.96411895751953,117.48877716064453,88.78923034667969C129.1479695638021,81.61434173583984,107.47382736206055,239.6113535563151,121.07621765136719,249.3273468017578C134.67860794067383,259.0433400472005,180.26903788248697,150.0747273763021,199.10311889648438,147.08518981933594C217.93719991048178,144.09565226236978,212.25708516438803,229.14796702067056,234.08070373535156,231.39012145996094C255.9043223063151,233.6322758992513,299.8505121866862,160.08968607584634,330.0448303222656,160.53811645507812C360.23914845784503,160.9865468343099,373.9910125732422,238.86396280924478,415.2466125488281,234.08070373535156C456.50221252441406,229.29744466145834,533.6322479248047,131.24065399169922,577.5784301757812,131.83856201171875C621.5246124267578,132.43647003173828,650.5231018066406,234.23018646240234,678.9237060546875,237.66815185546875C707.3243103027344,241.10611724853516,736.4723307291666,166.66665395100912,747.9820556640625,152.4663543701172"
            fill="none"
            strokeWidth={25}
            stroke='url("#SvgjsLinearGradient1001")'
            strokeLinecap="round"
        />
        <defs>
            <linearGradient id="SvgjsLinearGradient1001">
                <stop stopColor="hsl(37, 99%, 67%)" offset={0} />
                <stop stopColor="hsl(316, 73%, 52%)" offset={1} />
            </linearGradient>
        </defs>
    </svg>
);

const Item = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="border-b">
            <button
                type="button"
                aria-label="Open item"
                title="Open item"
                className="flex items-center justify-between w-full p-4 focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <p className="text-lg font-medium">{title}</p>


            </button>
            {isOpen && (
                <div className="p-4 pt-0">
                    <p className="text-gray-700">{children}</p>
                </div>
            )}
        </div>
    );
};



export const Faqs = () => {
    const { t, i18n } = useTranslation();
    let item1 = t('faq.item1') == 'faq.item1' ? 'How can I get in touch with GuulAcademy?' : t('faq.item1');
    let item2 = t('faq.item2') == 'faq.item2' ? 'How much does GuulAcademy cost? ' : t('faq.item2');
    let item3 = t('faq.item3') == 'faq.item3' ? 'How can i pay the fees' : t('faq.item3');
    let item4 = t('faq.item4') == 'faq.item4' ? 'How does GuulAcademy online tuition work?' : t('faq.item4');
    let item5 = t('faq.item5') == 'faq.item5' ? 'Group vs one-to-one: which is the best for my child?' : t('faq.item5');
    let item6 = t('faq.item6') == 'faq.item6' ? "How long will it take for me to see results in my child's progression?" : t('faq.item6');

    return (

        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
            <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
                <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <div>
                        <p class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                            FAQ
                        </p>

                    </div>
                    <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                        <span class="relative inline-block">
                            <SVGComponent />
                            <span class="relative"> Most</span>

                        </span>{' '}

                        Frequently Asked Questions
                    </h2>
                 {/*    <p class="text-base text-gray-700 md:text-lg ">
                        <Trans i18nKey='faq.faqtext'>
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                            accusantium doloremque rem aperiam, eaque ipsa quae.
                        </Trans>

                    </p> */}
                </div>
                <div class="space-y-4">


                    <Item title={item1} >
                        <Trans i18nKey='faq.item1desc'>
                            You can contact us on Whatsapp:+44 778 844 574
                            and email us at guulacademy@email.com for all general enquiries.
                        </Trans>
                    </Item>


                    <Item title={item2} >
                        <Trans i18nKey='faq.item2desc'>
                            We do our best to offer customers value for money. We have a variety of packages available based on the number of subjects and lessons you enroll on to. An average hourly cost is around $15ph.
                        </Trans>
                    </Item>

                    <Item title={item3}>
                        <Trans i18nKey='faq.item3desc'>
                            You can pay the fees by bank transfer which will be provided upon enrollment.
                        </Trans>

                    </Item>
                    <Item title={item4}>
                        <Trans i18nKey='faq.item4desc'>
                            GuulAcademy provides highly effective online tuition with weekly lessons tailored to your child's needs. Our skilled online tutors deliver a customized curriculum via live video, offering the same quality as face-to-face private tuition. All you need is a laptop or tablet with video capability, and your child can enjoy learning from the comfort of home.                        </Trans>
                    </Item>

                    <Item title={item5}>
                        <Trans i18nKey='faq.item5desc'>
                            At GuulAcademy, we offer small group sessions with a maximum size of 7 where a tutor mentors your child and a few peers together. This encourages a supportive atmosphere, fostering collaboration, celebrating achievements, and progressing through personalized programs. With tutor guidance students enhance their collaboration and communication skills.
                        </Trans>
                    </Item>

                    <Item title={item6}>
                        <Trans i18nKey='faq.item6desc'>
                            This question centers on individual students' commitment to homework, classwork, and revision. At GuulAcademy, our students are dedicated learners, passionately supported by their families. We aim to propel students beyond their potential, with many advancing an academic level within 6-8 months. Our primary focus is guiding your child toward continuous progress!
                        </Trans>
                    </Item>

                </div>
            </div>
        </div>
    );
};

function Faq(props, ref) {

    return (

        <section ref={ref}>
            <Faqs />
        </section>
    )
}

export default forwardRef(Faq)