import { useRef, useEffect } from 'react'
import background from '../../assets/images/steep.png'
import { forwardRef } from 'react'
import { motion, useInView, useAnimation } from 'framer-motion'
import { Trans } from 'react-i18next';


function Subjects(props, ref) {
    const mref = useRef(null);
    const eref = useRef(null);
    const sref = useRef(null);

    const mInView = useInView(mref, { once: true });
    const eInView = useInView(eref, { once: true });
    const sInView = useInView(sref, { once: true });

    const mControls = useAnimation();
    const eControls = useAnimation();
    const sControls = useAnimation();


    useEffect(() => {
        console.log('m is in view')
        if (mInView) {
            mControls.start('visible');
        }
        if (eInView) {
            eControls.start('visiblee');
        }

        if (sInView) {
            sControls.start('visible');
        }

    }, [mInView, eInView, sInView])




    return (
        <>
            <div ref={ref} className='' style={{ backgroundImage: `url(${background})` }}>
                <h3 class="mb-4 text-3xl font-extrabold flex justify-center md:text-3xl lg:text-4xl ">
                    <Trans i18nKey='subjects.maintitle'>
                        What We Teach At Guul Academy.
                    </Trans>
                </h3>
                <section ref={mref} className='' >
                    <motion.div
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }

                        }}
                        initial='hidden'
                        animate={mControls}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <div className="mx-auto max-w-screen-xl px-2 py-4 sm:py-12 sm:px-6 lg:py-16 lg:px-8 overflow-hidden rounded-lg shadow transition hover:shadow-lg mb-10">
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">

                                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80  lg:h-full">
                                    <img
                                        alt="Maths"
                                        src="https://images.unsplash.com/photo-1636466497217-26a8cbeaf0aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1548&q=80"
                                        className="absolute inset-0 h-full w-full object-cover"
                                    />
                                </div>

                                <div className="lg:py-24">
                                    <h2 className="text-3xl font-bold sm:text-4xl">
                                        <Trans i18nKey='subjects.mathtitle'>
                                            Maths
                                        </Trans>
                                    </h2>

                                    <p className="mt-4 text-gray-600">
                                        <Trans i18nKey='subjects.mathdesc'>
                                            At GuulAcademy, our math courses are designed to make complex concepts easy to understand.
                                            We focus on practical applications, ensuring that students not only grasp mathematical theory but can also use it in real-world scenarios.Our experienced instructors provide comprehensive support to students willing to embark on this journey.
                                        </Trans>
                                    </p>
                                    {/*  <a href="#" className="mt-8 inline-block rounded bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400">
                                Get Started Today
                            </a> */}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </section>
                <section ref={eref}
                >
                    <motion.div
                        variants={{
                            hiddene: { opacity: 0, y: 75 },
                            visiblee: { opacity: 1, y: 0 }

                        }}
                        initial='hiddene'
                        animate={eControls}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <div className="mx-auto max-w-screen-xl px-2 py-4 sm:py-12 sm:px-6 lg:py-16 lg:px-8 overflow-hidden rounded-lg shadow transition hover:shadow-lg mb-10">
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">

                                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80  lg:order-last lg:h-full">
                                    <img
                                        alt="English"
                                        src="https://images.unsplash.com/photo-1518082593638-b6e73b35d39a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGVuZ2xpc2h8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                                        className="absolute inset-0 h-full w-full object-cover"
                                    />
                                </div>

                                <div className="lg:py-24">
                                    <h2 className="text-3xl font-bold sm:text-4xl">
                                        <Trans i18nKey='subjects.englishtitle'>
                                            English
                                        </Trans>
                                    </h2>

                                    <p className="mt-4 text-gray-600">
                                        <Trans i18nKey='subjects.englishdesc'>
                                            Our English lessons cater to learners of all levels. We emphasize language proficiency, covering grammar, vocabulary, reading, writing, and speaking skills. Our curriculum is designed to enhance both academic and practical English usage, ensuring students can communicate effectively and write creatively
                                        </Trans>

                                    </p>
                                    {/*  <a href="#" className="mt-8 inline-block rounded bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400">
                                Get Started Today
                            </a> */}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </section>
                <section ref={sref}>
                    <motion.div
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }

                        }}
                        initial='hidden'
                        animate={sControls}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <div className="mx-auto max-w-screen-xl px-2 py-4 sm:py-12 sm:px-6 lg:py-16 lg:px-8 overflow-hidden rounded-lg shadow transition hover:shadow-lg ">
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">

                                <div className="relative  h-64 overflow-hidden rounded-lg sm:h-80  lg:h-full">
                                    <img
                                        alt="Party"
                                        src="https://images.unsplash.com/photo-1612454001981-ec4f7eed5cc8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjJ8fHNjaWVuY2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                                        className="absolute inset-0 h-full w-full object-cover"
                              />
                                </div>

                                <div className="lg:py-24">
                                    <h2 className="text-3xl font-bold sm:text-4xl">
                                        <Trans i18nKey='subjects.sciencetitle'>Science </Trans>
                                    </h2>

                                    <p className="mt-4 text-gray-600">
                                        <Trans i18nKey='subjects.sciencedesc'>
                                            Our lessons delve deep into scientific concepts, fostering curiosity and critical thinking. We provide engaging demonstrations, and access to cutting-edge resources to help students understand and appreciate the wonders of science.
                                            Our dedicated instructors guide learners through complex theories and practical applications, ensuring a comprehensive education in various scientific disciplines.
                                        </Trans>

                                    </p>
                                    {/*  <a href="#" className="mt-8 inline-block rounded bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400">
                                Get Started Today
                            </a> */}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </section>
            </div>
        </>
    )
}

export default forwardRef(Subjects)