import React from 'react'
import { useTranslation, Trans } from 'react-i18next';


/* 
<button key={'en'} onClick={() => i18n.changeLanguage(lng)}>
{lngs[lng].nativeName}
</button> */


function LanguageSwitch() {
    const { t, i18n } = useTranslation();

    return (
      /*   <div class="">
            <span className="inline-flex overflow-hidden rounded-md border bg-white shadow-sm">
                <button
                    key={'so'} className="inline-block border-e p-2 text-gray-700 hover:bg-gray-50 focus:relative"
                    onClick={() => i18n.changeLanguage('so')} >
                    <span class="text-2xl">🇸🇴</span>
                </button>

                <button
                    key={'so'} className="inline-block p-2 text-gray-700 hover:bg-gray-50 focus:relative"
                    onClick={() => i18n.changeLanguage('en')}
                >
                    <span class="text-lg">🇬🇧</span>
                </button>
            </span>
        </div> */
        <></>
    )
}

export default LanguageSwitch