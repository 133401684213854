import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      so: {
        translation: {
          navigation: {
            why: 'Sabab GA?',
            subjects: 'Madooyinka',
            registration: 'Diwaangalin',
            faq: 'Sualo',

          },
          banner: {
            place: 'Meel lagu horumariyo heerka waxbarashada',
            header: 'La xiriir oo wax ka baro khabiirada',
            grow: 'Kobci xirfadaada iyo cilmigaaga adigoo leh macallin sax ah.',
            contact:'Nala Soo Xiriir'

          },
          benefits: {
            choose: 'Sababta aad noo dooranaysid',
            choosedecs: 'Waaxan nahay academy aad ugu daadaleysa inee idin siiyaan waxbarasho aad u fiican. Waxbarashadeena sare waxaan ku bixinaa qiimo aad u wanaagsan. Waxaan ku dadaaleynaa inaan waxbarasho tayo leh siino qofkasta. Macallimiinteena khibrada  badan waxay abuuraan jawi waxbarasho oo firfircoon oo ku habboon baahiyahaaga shaqsiyeed.',
            affordable: 'Raqiis',
            affordabledesc: 'Qiimo Raqiis ah',
            global: 'Caalami ',
            globaldesc: 'Waxbarasho Calaami ah meelkaste oo aa dunida ka joogtid',
            flexible: 'Dabacsanaan',
            flexibledesc: 'Waqtigaad rabtid, iyo meshaa rabtid wax ka baro',
            instructors: 'Macalimin Khibrad leh',
            instructorsdesc: 'Macalimin wax bartay, Yaqano na sido wax lo baro',
            learning: 'Waxbarasho Adiga Ku Shaqsiyesan',
            learningdesc: 'Dadka dhan isku si looma barayo wax.',
            discounts: 'Qiimo Dhimis',
            discountsdesc: 'Qiimo Dhimis ayaa sinayna walalaha.'
          },

          subjects: {
            maintitle: 'Maadoyinka Aan Ku Dhigno Guul Academy',
            mathtitle: 'Xisaab',
            mathdesc: 'Waxbarashadeena Guul Academy, koorsooyinkeena xisaabta waxaa loogu talagalay in ay fududeeyaan fahamka fikradaha adag. Waxaan muhiimada saareynaa codsiyada wax ku oolka ah, waxaan hubineynaa in ardaydu aysan kaliya fahmin aragtida xisaabta laakiin sidoo kale ay u adeegsan karaan xaaladaha dhabta ah ee adduunka. Macallimiinteena khibrada leh waxay siiyaan taageero dhammaystiran ardayda doonaya inay bilaabaan safarkan.',
            englishtitle: 'Ingiriisi',
            englishdesc: 'Casharradeenna Ingiriisiga waxaa loogu talagalay ardayda heer kasta leh. Waxaan xoogga saarnaa aqoonta luuqada, oo ka kooban naxwaha, erayada, akhriska, qorista, iyo xirfadaha hadalka. Manhajkeena waxaa loogu talagalay in lagu wanaajiyo isticmaalka Ingiriisiga ee tacliinta iyo waxqabadka labadaba, iyadoo la hubinayo in ardaydu si wax ku ool ah ula xiriiri karaan oo ay si hal abuur leh u qori karaan.',
            sciencetitle: 'Saynis',
            sciencedesc: 'Casharradeenu waxay si qoto dheer ugu dhaadhacaan fikradaha cilmiga, kor u qaadida xiisaha iyo fekerka xasaasiga ah. waxaan bixinaa tusmooyin xiiso leh iyo helitaan qalab casri ah si aan uga caawino ardayda inay fahmaan una qaddariyaan cajiibka cilmiga. Macallimiinteena gaarka ah waxay ardayda ku hagaan aragtiyo adag iyo codsiyo la taaban karo, iyagoo hubinaya waxbarasho dhameystiran oo ku saabsan maadooyinka sayniska ee kala duwan.',
          },

          steps: {
            stepstitle:'Tilaaboyinka aad mareysid diwangalinta Guulacademy',
            step1: 'Tallaabada Koowaad',
            step1desc: 'Nala Soo Xiriir',
            step2: 'Tallaabada Labaad ',
            step2desc: 'Markaas ayaan ogaan doonnaa waxbarashooyinka iyo koorasyada aan kuu soo bandhigi karno. ',
            step3: 'Tallaabada Sadaxaadad',
            step3desc: 'Hadaa lo bahdo waxaan kuu soo direynaa imtixaan ku habboon maadooyinka aad qaadaneyso iyo kuwa aad u baahan tahay.',
            step4: 'Tallaabada Afaraad',
            step4desc: 'Waxaan ka wada hadli doonnaa jadwalka iyo qiimaha. Sido kale waxan ka wada hadlena qalabka iyo barnaamijyada loo baahan yahay',
            success: 'Waad Guuleysatay',
          },


          faq: {
            faq: 'FAQ',
            faqdesc: 'Sualaha Ugu Badan Nala Weydiyo',
            faqtext: '',
            item1: 'Sidee ayaan kula soo xiriiri karaa?',
            item1desc: 'Waxaad nagala soo xiriiri kartaa Whatsapp:+44 778 844 574 waxaadna email noogu soo dirtaa emailkan: Guulacademy@email.com.',
            item2: 'GuulAcademy lacagtiisa waa meeqa? ',
            item2desc: 'Waxaan aad isku dayeynaa in aan macaamiisheena u soo bandhigno qiimo wanaagsan. Waxaan leenahay xirmooyin kala duwan oo la heli karo oo ku saleysan tirada maadooyinka iyo casharrada aad iska diiwaangelisay. Celcelis ahaan qiimaha saacadu waa ilaa $15 saacadiiba.',
            item3: 'Qaabke lacagta loo bixin karaa?',
            item3desc: 'Lacagta waxaad ku bixin kartaa adigoo ku wareejinaya bangiga, kaas oo laguu soo dirayo marka aad is diiwaangeliso kabacdi. Qofkii doonayo xawaalad wuu ku soo diri karaa ',
            item4: 'Sidee bay u shaqaysaa GuulAcademy?',
            item4desc: 'GuulAcademy waxay bixisaa waxbarasho online ah oo tayo sare leh oo leh casharo todobaadle ah oo ku haboon baahida ilmahaaga. Macallimiinteena internetka ee xirfadleyda ah waxay bixiyaan manhaj u gaar ah iyagoo isticmaalaya muuqaal toos ah, oo leh tayo isku mid ah sida iskuulka. waxaad u baahantahay laptop ama tablet leh awood fiidiyoow ah, cunuggaaguna wuxuu bilaabi karaa waxbarasho guriga dhexdiisa ah.',
            item5: 'Guulacademy ma bixiyaa casharo kooxeed ama mid mid?',
            item5desc:'GuulAcademy waxaan ku bixinaa kooxo yaryar oo ugu badnaan 7 qof ah, kuwaas oo ilmahaaga iyo dhowr qof oo asxaabtiisa ah ay wadajir u hagtaan. Tani waxay dhiirigelisaa jawi taageero leh, kobcinta wada shaqeynta, u dabaaldega guulaha, iyo horumarka iyada oo loo marayo barnaamijyo shaqsiyeed. Iyada oo la adeegsanayo hagista macallinka ardayda waxay kor u qaadaan xirfadahooda wada shaqeynta iyo isgaarsiinta.',
            item6: "Muddo intee le'eg ayay igu qaadanaysaa inaan arko natiijada horumarka ilmahayga?",
            item6desc: "Su'aashani waxay ku saabsan tahay sida ardayga u go'an yahay inuu sameeyo shaqada guriga, shaqada fasalka, iyo dib u eegista. GuulAcademy, ardaydeenu waa arday heegan ah, oo si kal iyo laab ah u taageersan qoysaskooda. Waxaan higsaneynaa inaan ardayda ka gudubno awooddooda, iyadoo qaar badan ay sare u qaadeen heerka tacliinta 6-8 bilood gudahood. Ujeedada koowaad waxay tahay inaan ilmahaaga u hagno horumar joogto ah!",
          }
        }
      }
    }
  });

export default i18n;