import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      version="1.1"
      viewBox="0 0 12.7 12.7"
      xmlSpace="preserve"
    >
      <g transform="translate(-8.44 -12.613)">
        <g>
          <g
            strokeWidth="0.265"
            transform="matrix(.34286 0 0 .34286 4.674 -18.454)"
          >
            <path
              fill="#3f3d56"
              d="M29.504 90.612a18.53 18.53 0 00-18.513 17.952c-.005.188-.008.378-.008.569a18.52 18.52 0 1018.521-18.52zm0 36.513a18.01 18.01 0 01-17.991-17.992q0-.37.016-.73a17.991 17.991 0 1117.975 18.722z"
            ></path>
            <g transform="translate(98.505 9.483)">
              <path
                fill="#e6e6e6"
                d="M-53.39 99.65a15.61 15.61 0 01-31.221 0c0-.495.024-.982.069-1.464A15.61 15.61 0 01-53.39 99.65z"
              ></path>
              <path
                fill="#00a3ff"
                d="M-64.352 100.687a3.717 3.717 0 00-1.447-1.146 14.06 14.06 0 00-2.175-.714v-4.25a6.19 6.19 0 013.093 1.41l.862-1.838a5.705 5.705 0 00-1.682-1.04 8.27 8.27 0 00-2.136-.548v-2.153h-1.997v2.175a5.47 5.47 0 00-2.183.732 4.339 4.339 0 00-1.479 1.45 3.644 3.644 0 00-.526 1.93 3.297 3.297 0 00.605 2.063 3.84 3.84 0 001.508 1.196 13.941 13.941 0 002.212.733v4.072a6.611 6.611 0 01-3.68-1.469l-.862 1.842a6.504 6.504 0 001.968 1.116 9.039 9.039 0 002.437.508v2.135h1.997v-2.174a5.17 5.17 0 002.966-1.36 3.596 3.596 0 001.106-2.673 3.19 3.19 0 00-.587-1.997zm-5.345-2.31a3.836 3.836 0 01-1.33-.714 1.408 1.408 0 01-.471-1.106 1.78 1.78 0 01.481-1.273 2.435 2.435 0 011.32-.685zm2.977 5.63a2.264 2.264 0 01-1.254.654v-3.503a3.503 3.503 0 011.254.627 1.232 1.232 0 01.431.979 1.8 1.8 0 01-.431 1.243z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
