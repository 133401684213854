import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      version="1.1"
      viewBox="0 0 12.7 12.7"
      xmlSpace="preserve"
    >
      <g transform="translate(-26.718 -12.627)">
        <g transform="matrix(.04529 0 0 .04529 6.888 10.23)">
          <path
            fill="#3f3d56"
            d="M874.637 349.406a140.208 140.208 0 11-8.877-49.372c.305.823.604 1.646.894 2.478v.008q1.94 5.481 3.419 11.166a141.16 141.16 0 014.564 35.72z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M718.04 254.172a47.36 47.36 0 00-41.577-1.701c-5.813 2.53-11.934 7.068-13.873 15.263 7.843-4.628 17.996 2.685 20.014 14.417l-.47.13c-1.067 8.127 1.313 16.974 5.92 22.013a15.482 15.482 0 017.431-11.021c3.385-2.226 7.13-3.842 9.714-7.667 3.063-4.534 3.84-11.161 5.057-17.207 1.217-6.045 3.525-12.543 7.785-14.227z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M674.137 323.324c-2.172 3.262-5.074 5.309-7.677 7.857a17.673 17.673 0 00-5.584 10.438c-.33 2.839.18 5.724.11 8.594-.07 2.87-1.027 6.101-2.98 6.815-3.082 1.113-5.434-4.611-8.602-5.011-2.518-.314-4.784 3.646-4.055 7.081.73 3.435 4.157 4.956 6.125 2.713 1.78 2.988.525 8.407-2.149 9.324-.282 1.882-.564 3.757-.839 5.639-6.76-4.729-13.59-9.536-19.338-16.46a49.962 49.962 0 01-11.386-26.913c-.745-7.96.36-17.064-3.294-23.189-2.925-4.9-7.944-5.983-12.46-5.677-.824.055-1.655.149-2.478.25a139.609 139.609 0 0110.108-23.133 9.903 9.903 0 002.532-1.576c4.204-3.568 6.07-10.79 9.481-15.786q.33-.482.682-.94c3.49-4.557 8.164-6.901 12.68-9.082 10-4.854 20.287-9.77 30.843-9.096l-3.004.29q-5.258 14.727-10.524 29.454-.8 2.258-1.615 4.525c-1.992 5.583-4.046 11.527-3.74 17.777.18 3.702 1.466 7.654 3.912 8.924 2.282 1.177 4.776-.266 6.98-1.694.933-.596 1.858-1.192 2.791-1.795 3.349-2.157 7.058-4.368 10.595-2.925 3.238 1.317 5.63 5.716 6.085 10.46a20.914 20.914 0 01-3.2 13.136z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M694.052 390.353l-29.339-18.064.166-.427c-6.08 2.316-8.605 13.124-7.6 21.963 1.005 8.839 4.412 16.74 6.134 25.353a67.294 67.294 0 01-2.538 35.31 289.184 289.184 0 0033.971-46.81 25.044 25.044 0 003.073-6.814 10.84 10.84 0 00-.718-7.709 8.583 8.583 0 00-3.15-2.802z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M655.455 280.807a10.814 10.814 0 00-.495 7.864 8.717 8.717 0 004.87 4.51l5.403 2.679c1.798.891 3.736 1.791 5.568 1.058 2.21-.884 3.743-4.35 3.347-7.567-.422-3.423-2.58-5.778-4.576-7.783l-8.378-8.418-1.574 1.927a21.02 21.02 0 00-4.165 5.73z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M845.669 317.403l7.293 1.717c-.424 8.21-4.493 15.81-9.951 18.562-2.275 1.145-4.807 1.568-6.69 3.71-1.701 1.944-2.564 4.971-3.356 7.865l-4.164 15.15c-1.49 5.442-3.403 11.488-7.277 13.449-3.325 1.686-7.128-.377-9.685-3.835-2.556-3.458-4.117-8.108-5.63-12.64.094 4.94-2.204 9.786-5.458 11.503-3.262 1.717-7.23.165-9.41-3.67-1.569-2.752-2.22-6.383-3.756-9.175-1.537-2.792-4.76-4.391-6.439-1.772-1.105 1.74-.964 4.407-1.513 6.618a7.187 7.187 0 01-6.007 5.78 10.017 10.017 0 01-7.19-3.286 32.574 32.574 0 01-8.933-18.013 21.488 21.488 0 01.251-9.849c.965-3.05 3.294-5.403 5.607-4.823 1.521-3.215-1.482-7.48-4.187-7.104-2.714.376-4.839 3.285-7.066 5.52-2.22 2.235-5.324 3.858-7.56 1.64a3.066 3.066 0 01-2.872 3.248q-.12.007-.24.005a11.12 11.12 0 01-4.055-2.148c-5.12-3.192-11.621-1.075-15.26 4.963 2.274-9.676 5.607-20.78 12.594-23.055 2.854-.933 5.968-.164 8.665-1.78 4.776-2.862 5.74-13.676 1.616-18.138.525 4.22.423 9.144-1.804 12.03-2.227 2.886-6.9 1.003-6.618-3.263a2.858 2.858 0 01-5.317 1.13c-1.482-2.259-1.357-5.952-.306-8.65a20.373 20.373 0 014.572-6.493c3.725-3.991 7.504-8.03 11.817-10.54 4.321-2.509 9.309-3.355 13.637-.862 2.283 1.318 4.29 3.513 6.619 4.674a4.905 4.905 0 006.753-1.582q.04-.063.077-.128c1.733-3.042.918-7.56 1.56-11.362 1.153-6.72 7.874-9.865 11.599-5.427-2.784-.714-5.662 2.58-6.007 6.572-.353 3.991 1.662 8.022 4.36 9.23 4.533 2.007 8.971-3.043 12.398-7.717q5.364-7.34 10.743-14.672a17.727 17.727 0 011.921-2.337 6.182 6.182 0 018.446-.172c3.623 2.956 5.325 9.018 8.297 13.19 4.313 6.061 10.767 7.535 16.773 8.712q6.717 1.305 13.441 2.627a139.27 139.27 0 017.803 17.228 47.221 47.221 0 00-20.091 17.37z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M870.073 313.686a28.265 28.265 0 00-1.49 8.399c-3.843-4.69-4.454-13.48-1.93-19.566q1.942 5.482 3.42 11.167z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M860.145 411.756a140.595 140.595 0 01-11.684 19.55c-2.823-1.208-5.858.949-8.5 2.972-2.761 2.117-6.078 4.18-8.91 2.25-2.893-1.976-3.826-7.504-2.909-11.888a31.676 31.676 0 015.427-11.167c2.353-3.513 4.87-7.16 8.14-8.524 3.278-1.364 7.489.565 8.446 5.223l2.32-.079c-1.301-1.787-.619-5.489 1.13-6.14 2.015-.76 3.764 2.008 4.956 4.455.533 1.105 1.074 2.219 1.584 3.348z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M771.245 378.633c-4.989.314-8.94-5.828-11.567-11.87-2.626-6.043-5.022-12.953-9.524-16.029-2.572-1.757-5.505-1.998-8.295-2.85a10.27 10.27 0 01-7.044-6.202l-3.165-1.278a47.53 47.53 0 00-12.016 8.568 26.856 26.856 0 00-7.16 15.653c-.605 6.335 1.628 13.399 5.8 15.778 3.295 1.88 7.133.696 10.471 2.417 4.172 2.15 6.648 8.398 7.586 14.567.937 6.169.7 12.58 1.37 18.818.671 6.24 2.52 12.73 6.345 15.928 4.787 4 11.812.676 13.96-6.607 1.053-3.572 1.017-7.603 1.805-11.311 1.387-6.53 5.132-11.376 8.102-16.733 2.97-5.357 5.308-12.632 3.333-18.85z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M721.945 314.462a13.315 13.315 0 011.299 1.38 4.531 4.531 0 01-.08 4.679 4.353 4.353 0 003.118-1.585 20.04 20.04 0 002.39-3.367 3.67 3.67 0 00.705-2.937c-.236-.673-.793-.96-1.184-1.466a9.959 9.959 0 01-1.093-3.176 5.728 5.728 0 00-4.151-4.374l.25.048a2.372 2.372 0 00-1.111 2.526c.151.978.78 1.79.717 2.789-.09 1.435-1.524 2.109-1.612 3.544a2.74 2.74 0 00.751 1.939z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M713.396 290.074l.023-.004a4.005 4.005 0 00.425-.454z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M714.893 296.81a4.35 4.35 0 001.354-4.313c-.362-1.632-1.645-2.729-2.828-2.428a2.883 2.883 0 01-2.681.79c-.731-.189-1.535-.674-2.17-.123a3.284 3.284 0 00-.738 1.443l-.704 2.163a1.365 1.365 0 00-.084.82.694.694 0 00.533.422l4.227 1.246a3.642 3.642 0 003.09-.02z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M821.26 389.813l-1.953-3.199a10.687 10.687 0 00-2.575-3.232 2.281 2.281 0 00-3.214.283l-.034.042.08.609a13.755 13.755 0 013.431 6.696c.148 1.09.097 2.217.227 3.312a3.863 3.863 0 001.207 2.701c1.025.686 2.23-.325 2.96-1.557a5.364 5.364 0 00.83-3.414 5.987 5.987 0 00-.959-2.24z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M831.478 391.201a7.384 7.384 0 00-.239-2.977 6.536 6.536 0 01.995-3.705 12.045 12.045 0 001.55-3.398 2.842 2.842 0 00-.075-1.663c-.216-.485-.738-.705-1.037-.322l.64.413a5.837 5.837 0 00-2.6.126 3.22 3.22 0 00-1.972 2.222 13.756 13.756 0 01-.517 2.306c-.382.786-1.122 1.047-1.588 1.737a4.153 4.153 0 00.066 3.946 4.606 4.606 0 002.605 2.049c.783.28 1.814.295 2.172-.734z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M849.672 396.69a8.95 8.95 0 001.128 2.07c.87.877 2.055.338 3.111.125a5.38 5.38 0 015.118 2.053 13.027 13.027 0 00-7.492-9.348 22.011 22.011 0 00-7.705-1.205l-.448.115c1.14 3.32 4.721 3.245 6.288 6.19z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M772.61 403.48l.007.014.13-.25z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M769.85 408.822a24.52 24.52 0 00-1.548 3.402 29.613 29.613 0 00-1.123 5.82c-.14 1.123-.183 2.555.52 3.102a.997.997 0 001.304-.177 5.157 5.157 0 00.945-1.405 32.372 32.372 0 003.192-7.575 13.181 13.181 0 00-.523-8.495l-2.767 5.328z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M743.222 266.48a3.142 3.142 0 003.387.662 11.183 11.183 0 003.186-2.105 7.498 7.498 0 001.932-2.107 4.274 4.274 0 00.395-3.256 3.307 3.307 0 00-2.52-1.994l-4.692-1.391 1.131-1.02c-1.164-.624-2.451.535-3.179 1.97a10.606 10.606 0 00-.965 2.971 7.626 7.626 0 001.325 6.27z"
            transform="translate(-156.373 -156.01)"
          ></path>
          <path
            fill="#00a3ff"
            d="M770.833 257.212a2.584 2.584 0 003.52.286 3.936 3.936 0 001.558-2.805c.111-1.23-.497-2.597-1.366-2.704l.199-1.151c-1.674-.303-3.733-.363-4.63 1.67a4.771 4.771 0 00.719 4.704z"
            transform="translate(-156.373 -156.01)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
