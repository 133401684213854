import React from 'react'

function Modal({ setShowModal }) {
    return (
        <>
            <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-4/5 my-6 mx-auto max-w-xl ">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h5 className="text-2xl">
                                Contact Us
                            </h5>
                            <button
                                className="p-1 ml-auto  border-0 text-red-600 float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                            >
                                <span className="">
                                    X
                                </span>
                            </button>
                        </div>

                        <div className="relative p-6 flex-auto ">
                            <div className="rounded-lg  text-center overflow-hidden ">
                                <div className="px-6 py-5 w-full ">
                                    <button
                                        type="button"
                                        className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-5 py-2.5 text-center me-2 mb-2 w-full  break-words"
                                        onClick={()=>document.location="mailto:testing@mfoa.com?cc=testing&bcc=testing&subject=Hey%20I'd%20like%20to%20learn%20more%20about%20opportunities%20at%20guul"}

                                        
                                    >
                                      You can send an email to us at academyguul@gmail.com
                                    </button>

                                    <button

                                        type="button"
                                        className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-base px-5 py-2.5 text-center me-2 mb-2 w-full  break-words"
                                        onClick={()=>document.location='https://wa.me/447398191640?text=Hello+Im+Interested+in+getting+to+know+more+about+Guulacademy.'}
                                    >
                                       You can Whatsupp us at: +447398191640
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            <div className="rounded-lg border border-gray-100 text-center shadow-xl">
            </div>

        </>
    )
}

export default Modal