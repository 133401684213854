import {forwardRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faMoneyBills, faStopwatch, faChalkboardTeacher, faBook, faPercent } from '@fortawesome/free-solid-svg-icons'
import { Trans } from 'react-i18next';
import Moneyicon from '../../assets/icons/money-icon.jsx'
import Globeicon from '../../assets/icons/globe-icon.jsx'
import Goldmedal from '../../assets/icons/gold-medal.jsx';
import Premiumtick from '../../assets/icons/premium-tick.jsx';




function Benefits(props,ref) {    
    return (
        <>
            <section ref={ref} className=" m-15 p-12 bg-slate-800">
                {/*     <div className="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                <div
                    className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16"
                >
                    <div
                        className="mx-auto max-w-lg text-center lg:mx-0 ltr:lg:text-left rtl:lg:text-right"
                    >
                        <h2 className="text-3xl font-bold sm:text-4xl"> <Trans i18nKey="benefits.choose" >Why Choose Us</Trans></h2>

                        <p className="mt-4 text-gray-600">
                            <Trans i18nKey="benefits.choosedecs">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut vero
                                aliquid sint distinctio iure ipsum cupiditate? Quis, odit assumenda?
                                Deleniti quasi inventore, libero reiciendis minima aliquid tempora.
                                Obcaecati, autem.
                            </Trans>
                        </p>

                        <a
                            href=".block.rounded-xl"
                            className="mt-8 inline-block rounded bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                        >
                            Get Started Today
                        </a>
                    </div>

                    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
                        <a
                            className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                            href="/accountant"
                        >
                            <span className="inline-block rounded-lg bg-gray-50 px-4 py-3">
                                <FontAwesomeIcon icon={faMoneyBills} size="lg" style={{ color: "#7980ff", }} />
                            </span>

                            <h2 className="mt-2 font-bold">
                                <Trans i18nKey="benefits.affordable">
                                    Affordable
                                </Trans>
                            </h2>

                            <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                                <Trans i18nKey="benefits.affordabledesc">
                                    Our Prices are Affordable
                                </Trans>
                            </p>
                        </a>

                        <a
                            className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                            href="/accountant"
                        >
                            <span className="inline-block rounded-lg bg-gray-50 px-4 py-3">
                                <FontAwesomeIcon icon={faGlobe} size='lg' style={{ color: "#7980ff", }} />
                            </span>

                            <h2 className="mt-2 font-bold">
                                <Trans i18nKey="benefits.global">
                                    Global
                                </Trans>
                            </h2>

                            <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                                <Trans i18nKey="benefits.globaldesc">
                                    Completely Online
                                </Trans>
                            </p>
                        </a>

                        <a
                            className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                            href="/accountant"
                        >
                            <span className="inline-block rounded-lg bg-gray-50 px-4 py-3">
                                <FontAwesomeIcon icon={faStopwatch} size="lg" style={{ color: "#7980ff", }} />
                            </span>

                            <h2 className="mt-2 font-bold">
                                <Trans i18nKey="benefits.flexible">
                                    Flexible
                                </Trans>

                            </h2>

                            <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">

                                <Trans i18nKey="benefits.flexibledesc">
                                    Study Anywhere, with flexibility.
                                </Trans>
                            </p>
                        </a>

                        <a
                            className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                            href="/accountant"
                        >
                            <span className="inline-block rounded-lg bg-gray-50 p-3">
                                <FontAwesomeIcon icon={faChalkboardTeacher} size="lg" style={{ color: "#7980ff", }} />
                            </span>

                            <h2 className="mt-2 font-bold">
                                <Trans i18nKey="benefits.instructors">
                                    Experienced Instructors
                                </Trans>
                            </h2>

                            <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                                <Trans i18nKey="benefits.instructorsdesc">
                                    Experienced educators who are dedicated to your success
                                </Trans>
                            </p>
                        </a>

                        <a
                            className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                            href="/accountant"
                        >
                            <span className="inline-block rounded-lg bg-gray-50  px-4 py-3">
                                <FontAwesomeIcon icon={faBook} size="lg" style={{ color: "#7980ff", }} />
                            </span>

                            <h2 className="mt-2 font-bold">
                                <Trans i18nKey="benefits.learning">
                                    Personalised Learning
                                </Trans>

                            </h2>

                            <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                                <Trans i18nKey="benefits.learningdesc">
                                    Learning Tailored to you.
                                </Trans>
                            </p>
                        </a>

                        <a
                            className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                            href="/accountant"
                        >
                            <span className="inline-block rounded-lg bg-gray-50 px-4 py-3">
                                <FontAwesomeIcon icon={faPercent} size="lg" style={{ color: "#7980ff", }} />
                            </span>

                            <h2 className="mt-2 font-bold">
                                <Trans i18nKey="benefits.discounts">
                                    Discounts
                                </Trans>
                            </h2>

                            <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                                <Trans i18nKey="benefits.discountsdesc">
                                    Discounts availble for siblings
                                </Trans>
                            </p>
                        </a>
                    </div>
                </div>
            </div> */}
                <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
                    <div className="md:w-2/3 lg:w-1/2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#eab308"
                            className="w-6 h-6 text-secondary"
                        >
                            <path
                                fillRule="evenodd"
                                d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <h2 className="my-8 text-2xl font-bold text-zinc-200   md:text-4xl">
                            <Trans i18nKey="benefits.choose" >
                                Why Choose Guul Academy?
                            </Trans>
                        </h2>

                        <p className=" text-zinc-200 md:text-xl">
                            <Trans i18nKey="benefits.choosedecs">
                            We are an academy that does our best to provide you with an excellent education. We offer our premium tuition at an excellent price, striving to make quality education accessible to everyone. Our experienced and passionate instructors create a dynamic learning environment tailored to your individual needs. 
                            </Trans>
                        </p>
                    </div>
                    <div className="mt-16 grid divide-x divide-y divide-gray-100 dark:divide-gray-700 overflow-hidden rounded-3xl border border-gray-100 text-gray-600 dark:border-gray-700 sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4">
                        <div className="group relative bg-white transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                            <div className="relative space-y-8 py-12 p-8">
                            <Goldmedal/>
                                <div className="space-y-2">
                                    <h5 className="text-xl font-semibold text-gray-700  transition group-hover:text-secondary">
                                        <Trans i18nKey="benefits.instructors">
                                            Experienced Instructors
                                        </Trans>
                                    </h5>
                                  
                                        <Trans i18nKey="benefits.instructorsdesc">
                                            Experienced educators who are dedicated to your success
                                        </Trans>
                                    
                                </div>
                                <a
                                    href="#"
                                    className="flex items-center justify-between group-hover:text-secondary"
                                >
                                    {/* <span className="text-sm">Read more</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100"
          >
            <path
              fillRule="evenodd"
              d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg> */}
                                </a>
                            </div>
                        </div>
                        <div className="group relative bg-white  transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                            <div className="relative space-y-8 py-12 p-8">
                                <Moneyicon />
                                <div className="space-y-2">
                                    <h5 className="text-xl font-semibold text-gray-700  transition group-hover:text-secondary">
                                        <Trans i18nKey="benefits.affordable">
                                            Affordable
                                        </Trans>
                                    </h5>
                                    <Trans i18nKey="benefits.affordabledesc">
                                        Our Prices are affordable and well worth it. Discounts available for siblings.
                                    </Trans>
                                </div>
                                <a
                                    href="#"
                                    className="flex items-center justify-between group-hover:text-secondary"
                                >
                                </a>
                            </div>
                        </div>
                        <div className="group relative bg-white transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                            <div className="relative space-y-8 py-12 p-8">
                                <Globeicon />
                                <div className="space-y-2">
                                    <h5 className="text-xl font-semibold text-gray-700 transition group-hover:text-secondary">
                                        <Trans i18nKey="benefits.global">
                                            Global
                                        </Trans>
                                    </h5>
                                  
                                        <Trans i18nKey="benefits.globaldesc">
                                            All learning can be done completely online. From anywhere in the world.
                                        </Trans>
                                  
                                </div>
                                <a
                                    href="#"
                                    className="flex items-center justify-between group-hover:text-secondary"
                                >
                                </a>
                            </div>
                        </div>
                        <div className="group relative bg-white  transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-800/10">
                            <div className="relative space-y-8 py-12 p-8 transition duration-300 group-hover:bg-white ">
                               <Premiumtick/>
                                <div className="space-y-2">
                                    <h5 className="text-xl font-semibold text-gray-700 transition group-hover:text-secondary">
                                        <Trans i18nKey="benefits.learning">
                                            Personalised Learning
                                        </Trans>
                                    </h5>
                                        <Trans i18nKey="benefits.learningdesc">
                                            Learning Tailored to you and your needs.
                                        </Trans>
                              
                                </div>
                                <a
                                    href="#"
                                    className="flex items-center justify-between group-hover:text-secondary"
                                >
                                       {/* <span className="text-sm">Read more</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100"
          >
            <path
              fillRule="evenodd"
              d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg> */}
                                </a>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                    fill="#1e293b"
                    d="M0 192l120 16c120 16 360 48 600 42.7 240-5.7 480-47.7 600-69.4l120-21.3V0H0z"
                ></path>
            </svg>
        </>
    )
}

export default forwardRef(Benefits)


