import { useState, useRef } from 'react'
import About from '../../components/About/About.jsx'
import Banner from '../../components/Banner/Banner.jsx'
import Faq from '../../components/FAQ/Faq.jsx'
import Benefits from '../../components/Benefits/Benefits.jsx'
import Subjects from '../../components/Subjects/Subjects.jsx'
import Footer from '../../components/Footer/Footer.jsx'
import Navigation from '../../components/Navigation/Navigation.jsx'
import Pricing from '../../components/Pricing/Pricing.jsx'
import Steps from '../../components/Steps/Steps.jsx'
import LanguageSwitch from '../../components/Navigation/LanguageSwitch'
import { useTranslation } from 'react-i18next';




function Home() {
    const { t, i18n } = useTranslation();
    // the buttions are inside the banner
    // The stuff whose references are needed is being rendered here.
    //need to pass the references from the components inside here to the banner
    //which will then use the refs.

    const whyRef = useRef(null)
    const subjectsRef = useRef(null)
    const stepsRef = useRef(null)
    const faqRef = useRef(null)

    const handleNavClicks = (e, eRef) => {
        e.preventDefault();
        eRef.current?.scrollIntoView({ behavior: "smooth",  });
        console.log(eRef);
    }


    return (
        <>
            <LanguageSwitch />
            {/* <Navigation /> */}
            <Banner handleNavClicks={handleNavClicks} whyRef={whyRef} subjectsRef={subjectsRef} stepsRef={stepsRef} faqRef={faqRef} />
            <Benefits ref={whyRef} />
            <Subjects ref={subjectsRef} />
            {/*   <Pricing /> */}
            <Steps ref={stepsRef} />
            <Faq ref={faqRef} />
            {/* <About />  */}
            <Footer />
        </>
    )
}


export default Home 